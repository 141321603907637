import { Card, CardContent, CardMedia, Typography, makeStyles } from "@material-ui/core";
import { Service, Services } from '../../../../helpers/types';
import React from "react";
import classNames from "classnames";
import { primaryColour, transparentBlue } from "assets/jss/material-dashboard-react";

export default ({
  service,
  setService,
  selectedService,
}: ServiceCardProps) => {

  const classes = useStyles();

  const {
    label,
    img,
    value
  } = service;

  return (
    <Card
      className={classNames(classes.card, {
        [classes.activeService]: value === selectedService
      })}
      onClick={() => setService(value)}
    >
      <CardContent>
        <img
          className="lazyload"
          data-src={img}
          alt={label}
          style={{maxHeight: 120, width: 'auto', }}
        />
        <Typography className={classes.label}>
          {label}
        </Typography>
      </CardContent>
    </Card>
  );
}

// ============================================================================
// Styles
// ============================================================================
//@ts-ignore
const styles = theme => ({
  activeService: {
    backgroundColor: transparentBlue,
  },
  card: {
    borderRadius: 16,
    border: '1px solid ' + primaryColour[100],
    display: 'flex',
    maxWidth: 171,
    height: 192,
    width: '100%',
    padding: 16,
    flexDirection: 'column',
    alignItems: 'center',
    gap: 16,
    cursor: 'pointer',
    transition: 'all .5s',
    '& .MuiCardContent-root': {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    '&:hover': {
      backgroundColor: transparentBlue,
    },
    [theme.breakpoints.down("sm")]: {
      width: 137,
      height: 'auto',
      padding: '16px 0',
    },
    '& img': {
      [theme.breakpoints.down("sm")]: {
        maxWidth: 88,
        maxHeight: '83px!important',
        height: '100%'
      }
    }
  },
  label: {
    color: primaryColour[900],
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    textAlign: 'center',
    marginTop: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    }
  }
});
//@ts-ignore
const useStyles = makeStyles(styles);

// ============================================================================
// Types & Interfaces
// ============================================================================

interface ServiceCardProps {
  service: Service;
  setService: (service: Services) => void;
  selectedService: Services;
}